class WebSocketService {
    constructor(url) {
        this.socket = new WebSocket(url)
        this.listeners = {
            open: [],
            message: [],
            error: [],
            close: [],
        }

        this.socket.onopen = (event) => {
            this.listeners.open.forEach(listener => listener(event))
        }

        this.socket.onmessage = (event) => {
            this.listeners.message.forEach(listener => listener(event))
        }

        this.socket.onerror = (event) => {
            this.listeners.error.forEach(listener => listener(event))
        }

        this.socket.onclose = (event) => {
            this.listeners.close.forEach(listener => listener(event))
        }
    }

    isConnected() {
        return this.socket && (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)
    }

    send(message) {
        if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message)
        } else {
            console.error('WebSocket is not open.')
        }
    }

    addListener(type, listener) {
        if (this.listeners[type]) {
            this.listeners[type].push(listener)
        } else {
            console.error('Invalid listener type.')
        }
    }

    removeListener(type, listener) {
        if (this.listeners[type]) {
            const index = this.listeners[type].indexOf(listener)
            if (index !== -1) {
                this.listeners[type].splice(index, 1)
            }
        } else {
            console.error('Invalid listener type.')
        }
    }

    close() {
        if (this.socket) {
            this.socket.close()
        }
    }
}

export default WebSocketService
